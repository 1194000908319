import axios from 'axios';
import {
  REACT_APP_BASE_URL_BACKEND_ROP_STATE_ACCOUNT,
  REACT_APP_BASE_URL_BACKEND_ROP_STATE_ACCOUNT_API_KEY,
} from 'src/utils/constants';

const getReportByInvestorrRop = async ({
  page = 1,
  rowsPerPage = 10,
  documentNumber,
}) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL_BACKEND_ROP_STATE_ACCOUNT}/report/investor-intranet/?investor_uuid=${documentNumber}`,

      {
        params: {
          page: page + 1,
          size: rowsPerPage,
        },
        headers: {
          'x-api-key': `${REACT_APP_BASE_URL_BACKEND_ROP_STATE_ACCOUNT_API_KEY}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (error) {
      if (error.message === 'Network Error') {
        console.error('Error de red:', error.message);
      }
      return error.response?.data || error.message;
    }
    console.error('Error desconocido:', error);
    throw error;
  }
};

export default getReportByInvestorrRop;
